export const educationData = [
  {
    id: 1,
    institution: "MAN 1 Garut",
    course: "Islamic Religious Science",
    startYear: "2017",
    endYear: "2020",
  },
  {
    id: 2,
    institution: "Sunan Gunung Djati State Islamic University Bandung",
    course: "Informatics Engeneering",
    startYear: "2020",
    endYear: "Now",
  },
];
