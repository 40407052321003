export const tugasData = [
  {
    id: 1,
    institution: "Praktikum Pengemangan Aplikasi Web",
    course: "UTS Game TTS Sederhana",
    startYear: "2023",
    assestmen: "https://game-tts.vercel.app/",
  },
   {
    id: 2,
    institution: "Praktikum Pengemangan Aplikasi Web",
    course: "UAS Membuat Aplikasi CRUD",
    startYear: "2023",
    assestmen: "https://crud-futsal-kn2p.vercel.app/",
  },
];
