import React from "react";
import { Helmet } from "react-helmet";

import { Navbar, Footer, Landing, About, Skills, Testimonials, Education, Tugas, Experience, Contacts, Projects, Services, Achievement } from "../../components";
import { headerData } from "../../data/headerData";

function Main() {
  return (
    <div>
      <Helmet>
        <title>{headerData.name} - Porfolio</title>
      </Helmet>

      <Navbar />
      <Landing />
      <About />
      <Education />
      <Tugas />
      <Skills />
      <Experience />
      <Projects />
      <Contacts />
      <Footer />
    </div>
  );
}

export default Main;
